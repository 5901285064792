import React 			from 'react'
import { connect } 		from 'react-redux'
import classnames 		from 'classnames'
import requestFrame     from 'request-frame'
import TWEEN            from '@tweenjs/tween.js'
import {
	isFinite,
	isFunction
} from 'lodash'
import {
	mapRange
} from 'canvas-sketch-util/math'

import Breadcrumbs    	from '../breadcrumbs'
import Footer 			from '../footer'

import breakpoints 		from '../../lib/constants/breakpoints.json'

class PageScroll extends React.Component {
	constructor(props) {
		super(props)

		this.mouseWheelHandler = this.mouseWheelHandler.bind(this)
		this.keydownHandler = this.keydownHandler.bind(this)
		this.loopUpdate = this.loopUpdate.bind(this)

		this.shiftY = 0
		this.prevDeltaYAbs = 0
		this.mouseWheelActive = false
		this.mouseWheelIgnore = false
		this.isAnimating = false

		const sectionCount = this.sectionCount(props)
		const initIndex = isFinite(props.scrollIndex) && props.scrollIndex < sectionCount ? props.scrollIndex : 0

		this.state = {
			activeIndex: initIndex,
			scrollIndex: initIndex,
			scrollCount: sectionCount,
			shouldBypass: this.shouldBypass(props)
		}
	}

	componentDidMount() {
		this.tweens = new TWEEN.Group()

		this.bindHandlers()
	}

	componentDidUpdate(prevProps, prevState){
		const {
			shouldBypass
		} = this.state
		const {
			screenSize,
			scrollIndex
		} = this.props

		if(this.sectionCount(prevProps) !== this.sectionCount(this.props)){
			this.setState({
				scrollCount: this.sectionCount(this.props)
			})
		}

		if(isFinite(scrollIndex) && scrollIndex !== prevProps.scrollIndex && scrollIndex < this.sectionCount(this.props)){
			this.animateTo(scrollIndex)
		}

		if(prevProps.screenSize.width !== screenSize.width){
			const _shouldByPass = this.shouldBypass()
			if(_shouldByPass !== shouldBypass){
				this.setState({
					shouldBypass: _shouldByPass
				})
			}
		}

		if(prevState.shouldBypass !== shouldBypass){
			this.bindHandlers()
		}
	}

	componentWillUnmount() {
		this.unbindHandlers()
	}

	shouldBypass(_props = null){
		const props = _props ? _props : this.props
		const {
			screenSize
		} = props

		return screenSize.width <= breakpoints.tabletPortrait
	}

	bindHandlers(){
		const {
			shouldBypass
		} = this.state

		this.unbindHandlers()

		if(!shouldBypass){
			window.addEventListener('wheel', this.mouseWheelHandler, {
				passive: false
			})
			document.addEventListener('keydown', this.keydownHandler)
			this.setLoop()
		}
	}

	unbindHandlers(){
		this.clearMouseWheelTO()
		this.cancelLoop()
		this.tweens.removeAll()
		window.removeEventListener('wheel', this.mouseWheelHandler)
		document.removeEventListener('keydown', this.keydownHandler)
	}

	loopUpdate(){
		this.setLoop()

		const {
			activeIndex,
			scrollIndex
		} = this.state

		this.tweens.update()
		
		if(Math.abs(this.shiftY) >= 0.001){
			let nScrollIndex = this.shiftIndex(scrollIndex, this.shiftY)

			if(nScrollIndex !== scrollIndex){
				this.setState({
					scrollIndex: nScrollIndex
				})
			}

			if(Math.abs(activeIndex - scrollIndex) > 0.125){
				let newIndex = activeIndex
				if(this.shiftY > 0){
					newIndex += 1
				} else {
					newIndex -= 1
				}
				newIndex = this.wrapIndex(Math.round(newIndex))
				this.animateTo(newIndex, 800)
			} else {
				this.setSnapTO()
			}
			
			this.shiftY = 0
		}
	}

	setSnapTO(){
		this.clearSnapTO()

		this.snapTO = setTimeout(() => {
			let nScrollIndex = this.wrapIndex(Math.round(this.state.scrollIndex))
			this.animateTo(nScrollIndex, 300)
		}, 500)
	}

	clearSnapTO(){
		if(this.snapTO){ clearTimeout(this.snapTO) }
	}

	navDirection(dir = 'prev'){
		const {
			scrollIndex
		} = this.state

		this.clearSnapTO()

		let toIndex = scrollIndex
		if(dir === 'next'){
			toIndex = scrollIndex + 1
		} else {
			toIndex = scrollIndex - 1
		}

		return this.animateTo(this.wrapIndex(Math.round(toIndex)), 1200)
	}

	animateTo(index, time = 900){
		const {
			scrollIndex,
			shouldBypass
		} = this.state

		this.clearSnapTO()

		this.tweens.removeAll()

		if(shouldBypass){ return }

		this.shiftY = 0
		this.isAnimating = true

		this.setState({
			activeIndex: index
		})

		const tween = new TWEEN.Tween({
				i: scrollIndex
			}, this.tweens)
			.to({
				i: index
			}, time)
			.easing(TWEEN.Easing.Cubic.Out)
			.onUpdate(({ i }) => {
				this.setState({
					scrollIndex: i
				})
			})
			.onComplete(({ i }) => {
				this.setState({
					activeIndex: i,
					scrollIndex: i
				})
				this.isAnimating = false
			})
		tween.start()
	}

	wrapIndex(index){
		const {
			scrollCount
		} = this.state

		let newIndex = index
		if(index < 0){
			newIndex = 0
		} else if(index >= scrollCount){
			newIndex = scrollCount - 1
		}
		return newIndex
	}

	shiftIndex(index, shift){
		const {
			scrollCount
		} = this.state

		let newIndex = index + shift
		if(newIndex < 0){
			if(shift < 0){
				const totalBefore = Math.abs(newIndex)
				newIndex = index - this.extensionAdjustment(shift, totalBefore)
			}
		} else if(newIndex >= scrollCount - 1){
			if(shift > 0){
				const totalAfter = Math.abs(newIndex) - scrollCount
				newIndex = index + this.extensionAdjustment(shift, totalAfter)
			}
		}

		return newIndex
	}

	extensionAdjustment(shift, total){
		let y = Math.abs(shift)

		const maxShift = 0.2
		const percOver = 1 - Math.max(Math.min((shift + total / maxShift), 1), 0.9)

		y = y * percOver

		return y * 0.5
	}

	setLoop(){
		this.cancelLoop()

		const request = requestFrame('request')
		this.loopID = request(this.loopUpdate)
	}

	cancelLoop(){
		if(this.loopID){
			const cancel = requestFrame('cancel')
			cancel(this.loopID)
		}
	}

	keydownHandler(e){
		switch(e.keyCode){
			case 37:
			case 38:
				this.navDirection('prev')
				break
			case 39:
			case 40:
				this.navDirection('next')
				break
			default:
				return null
		}
	}

	mouseWheelHandler(e){
		const {
			overlay,
			screenSize
		} = this.props
		const {
			deltaX,
			deltaY
		} = e	

		if(!!overlay){
			return
		}

		const deltaYAbs = Math.abs(deltaY)
		if(deltaYAbs > 0){
			e.preventDefault()

			this.mouseWheelIgnore = false
			this.mouseWheelActive = true

			if(
				!this.isAnimating &&
				(
					!this.mouseWheelIgnore || deltaYAbs > this.prevDeltaYAbs
				)
			){
				this.tweens.removeAll()

				const toShift = (deltaY / screenSize.height) * 0.65
				this.shiftY = this.shiftY + toShift
			} else {
				this.mouseWheelIgnore = true
			}

			this.prevDeltaYAbs = deltaYAbs
			this.setMouseWheelTO()
		}
	}

	setMouseWheelTO(){
		this.clearMouseWheelTO()

		this.mouseWheelTO = setTimeout(() => {
			this.mouseWheelIgnore = false
			this.mouseWheelActive = false
		}, 250)
	}

	clearMouseWheelTO(){
		if(this.mouseWheelTO){ clearTimeout(this.mouseWheelTO) }
	}

	onArrowClick(e){
		e.preventDefault()
		e.stopPropagation()

		this.navDirection('next')
	}

	sectionCount(props = null){
		let _props = props
		if(!props){
			_props = this.props
		}

		return _props.children.length
	}

	render() {
		const {
			activeIndex,
			scrollIndex,
			scrollCount,
			shouldBypass
		} = this.state
		const {
			children,
			screenSize,
			breadCrumbDark,
			showFooter
		} = this.props

		if(shouldBypass){
			return <React.Fragment>
				{ children }
				{ showFooter ? <Footer /> : null }
			</React.Fragment>
		}

		const sectionHeight = screenSize.height

		const scrollPx = scrollIndex * sectionHeight
		const translate = `translate3d(0,${ scrollPx * -1 }px,0)`
		const scrollHeight = sectionHeight * this.sectionCount()

		return <div
			className={ classnames('pageScroll-window') }
			style={{
				height: sectionHeight
			}}>
			<Breadcrumbs
				activeIndex={ activeIndex }
				dark={ breadCrumbDark[activeIndex] }
				onCrumbSelect={ (index) => {
					this.animateTo(index)
				} }
				length={ children.length }
				vertical={ true } />
			<div className={ classnames('pageScroll-scroll') }
				style={{
					height: scrollHeight,
					WebkitTransform: translate,
					transform: translate
				}}>
				{ React.Children.map(children, (_child, _i) => {
					const _minScrollPx = (_i - 1) * sectionHeight
					const _maxScrollPx = (_i + 1) * sectionHeight
					const _scrollPx = Math.max(_minScrollPx, Math.min(_maxScrollPx, scrollPx)) - (_i * sectionHeight)

					return React.cloneElement(_child, { 
						fixed: true,
						isActive: Math.abs(_i - scrollIndex) < 0.05,
						indexIsActive: _i === activeIndex,
						scrollPx: _scrollPx,
						scrollPxInView: Math.abs(_scrollPx) < sectionHeight,
						isLast: _i >= (scrollCount - 1),
						showIndicator: _i === 0
					})
				}) }
				{ showFooter ? <Footer /> : null }
			</div>
		</div>
	}
}

const mapStateToProps = (state) => {
	const {
		ui
	} = state

	return {
		overlay: ui.overlay,
		screenSize: ui.screenSize
	}
}

export default connect(mapStateToProps)(PageScroll)