import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'gatsby'
import { get, findIndex } from 'lodash'

import PageLayout from '../components/page-layout'
import {
  SectionHouses,
  SectionImageScroll,
  SectionImage,
  SectionSplitIllustration,
  SectionText,
} from '../components/section'
import { GridSection } from '../components/grid'
import PageScroll from '../components/page-scroll'
import { getSEOFromPage } from '../components/seo'

import breakpoints from '../lib/constants/breakpoints.json'
import {
  formatAcfImageForARYImage,
  formatWpImageForARYImage,
} from '../lib/_helpers'

import { openOverlay } from '../redux/actions'

import i1 from '../assets/imgs/home/house_render_1.jpg'
import i2 from '../assets/imgs/home/house_render_2.jpg'
import i3 from '../assets/imgs/home/house_render_3.jpg'
import i4 from '../assets/imgs/home/house_render_4.jpg'

class PageHouses extends React.Component {
  render() {
    const { dispatch, data, screenSize, overlay, location } = this.props

    const gallery = get(data, 'gallery.edges')

    return (
      <PageLayout seo={getSEOFromPage(get(data, 'page'))} location={location}>
        <PageScroll breadCrumbDark={[false, false, false, false, true]}>
          <SectionImageScroll
            title="Quality contemporary homes"
            body={`
						<p>
							Designed and delivered by experts who take care of everything.
							Brilliant design and performance for a joyful living experience.
							With enough flex to make it your own at a fixed price.
						</p>
					`}
            ignoreCTA={true}
            image={{
              src: i1,
              width: 2400,
              height: 1529,
            }}
            delayTypeFade={true}
            showIndicator={true}
          />
          <SectionImageScroll
            title="Built to the international gold standard — Passivhaus"
            body={`
						<p>
							Using the best materials and construction methods.
							Easy to clean and maintain, well planned spaces that put people and quality first.
							Because our homes should not cost the earth to run.
						</p>
					`}
            ignoreCTA={true}
            image={{
              src: i2,
              width: 2400,
              height: 1800,
            }}
            delayTypeFade={true}
          />
          <SectionImageScroll
            title="A streamlined process"
            body={`
						<p>
							We’ve removed the inefficiency of building one-off homes.
							We’ve created a simple end to end process, so you can enjoy the experience.
						</p>
					`}
            ignoreCTA={true}
            image={{
              src: i3,
              width: 2400,
              height: 1529,
            }}
            delayTypeFade={true}
          />
          <SectionImageScroll
            title="Because our home is our sanctuary"
            body={`
						<p>
							All housing can and should be brilliant. 
							It’s about caring deeply and putting people and quality first.
							To create a home where everything ‘just feels right’.
						</p>
					`}
            ignoreCTA={true}
            image={{
              src: i4,
              width: 2400,
              height: 1800,
            }}
            delayTypeFade={true}
          />
          <SectionHouses title="The houses" />
        </PageScroll>
      </PageLayout>
    )
  }
}

const mapStateToProps = (state) => {
  const { ui } = state

  return {
    overlay: ui.overlay,
    screenSize: ui.screenSize,
  }
}

export default connect(mapStateToProps)(PageHouses)

export const query = graphql`
  query($id: String!) {
    page: wordpressPage(id: { eq: $id }) {
      title
      slug
      path
      wordpress_id
      seo_meta {
        title
        description
      }
    }
  }
`
