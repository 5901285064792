import React 			    from 'react'
import ReactDOM             from 'react-dom'
import Measure              from 'react-measure'
import classnames           from 'classnames'
import { connect }          from 'react-redux'
import {
    isFinite,
    isFunction,
    chunk,
    sumBy
} from 'lodash'
import {
    Link
} from 'gatsby'

import ARYImage             from '../ary-image'
import Toggle               from '../toggle'

import ScrollInViewport     from '../scroll-in-viewport'

import {
    formatTitle,
    renderHTML
} from '../../lib/_helpers'
import {
    ratioFromImage,
    roundTo
} from '../../lib/mathHelpers'

const GridSpacer = ({ height }) => {
    return height && isFinite(height) ? <div className="grid-item-spacer">
        <div className="grid-item-spacer-space"
            style={{
                height: roundTo(height, 100)
            }} />
    </div> : null
}

const GridMeasure = (props) => {
    const {
        count,
        onSetWidth
    } = props

    return <div className="cols cols--gridMeasure">
        <div className={ classnames('col', `col--1-${ count }`) }>
            <Measure
                bounds
                onResize={ (contentRect) => {
                    if(onSetWidth && isFunction(onSetWidth)){
                        onSetWidth(contentRect.bounds.width)
                    }
                } }
                >
                { ({ measureRef} ) => {
                    return <div
                        ref={ measureRef }
                        className="gridMeasureEl" />
                } }
            </Measure>
        </div>
    </div>
}

class Grid extends React.Component {
    constructor(props) {
        super(props)

        const initState = this.calcStateFromElements(props)

        this.onSetWidth = this.onSetWidth.bind(this)

        this.state = {
            hoveredIndex: null,
            gridItemWidth: 0,
            margin: 25,
            ...initState
        }
    }

    componentDidUpdate(prevProps){
        if(
            (prevProps.elements && this.props.elements && prevProps.elements.length !== this.props.elements.length)
            || (prevProps.colBase !== this.props.colBase)
        ){
            this.setState({
                ...this.calcStateFromElements()
            })
        }
    }

    onClickElement(index, indexInRow){
        const {
            colsPerRow
        } = this.state
        const {
            onClickElement
        } = this.props

        if(onClickElement && isFunction(onClickElement)){
            onClickElement(index, indexInRow < colsPerRow / 2 ? 'left' : 'right')
        }
    }

    calcStateFromElements(props = null){
        const _props = props ? props : this.props
        const {
            colBase,
            firstRowMax,
            elements,
            ignoreFeatured
        } = _props

        const _colBase = isFinite(colBase) ? colBase : 4

        const colRemainder = elements.length % _colBase
        let firstRowAdjustment = _colBase > 4 && colRemainder <= 2 && colRemainder > 0 ? colRemainder : null
        if(isFinite(firstRowMax)){
            firstRowAdjustment = Math.max(_colBase - firstRowMax, firstRowAdjustment)
        }
        const canBeFeatured = _colBase >= 2 && !ignoreFeatured

        return {
            colsPerRow: _colBase,
            firstRowAdjustment: firstRowAdjustment,
            canBeFeatured: canBeFeatured
        }
    }

    wrapBody(body){
        const {
            bodyIsHTML
        } = this.props

        if(!body){ return null }

        const _content = renderHTML(body)

        return bodyIsHTML ? _content : <p>{ _content }</p>
    }

    heightForImage(image, isFeatured){
        const {
            margin,
            gridItemWidth
        } = this.state

        if(!image){ return null }

        const width = isFeatured ? (gridItemWidth * 2) + margin : gridItemWidth
        const ratio = ratioFromImage(image)

        return width * ratio
    }

    onSetWidth(width){
        const {
            gridItemWidth
        } = this.state

        if(width !== gridItemWidth){
            this.setState({
                gridItemWidth: width
            })
        }
    }

    render() {
        const {
            hoveredIndex,
            colsPerRow,
            firstRowAdjustment,
            canBeFeatured,
            gridItemWidth
        } = this.state
        const {
            elements,
            activeIndex,
            colBase,
            firstRowMax,
            colour,
            titleAsBody,
            textBlockClassName,
            bodyIsHTML,
            elementCTA,
            ignoreFeatured,
            limitRatio,
            onClickElement
        } = this.props

        const hasHighlight = isFinite(activeIndex) && activeIndex >= 0 && activeIndex < elements.length

        const rows = [[]]
        let rowI = 0
        let colI = 0
        for(let i = 0; i < elements.length; i++){
            const _element = elements[i]
            const _isFeatured = !!_element.featured && canBeFeatured
            const _w = _isFeatured ? 2 : 1

            colI += _w

            if(
                (isFinite(firstRowAdjustment) && rows.length === 1 && colI > colsPerRow - firstRowAdjustment)
                || colI > colBase
            ){
                rows.push([])
                rowI++
                colI = _w
            }

            rows[rowI].push({
                index: i,
                ..._element
            })
        }

        return <div
            className={ classnames('grid', {
                'grid--hasHighlight': hasHighlight
            }) }>
            <GridMeasure
                onSetWidth={ this.onSetWidth }
                count={ colsPerRow } />
            { rows && rows.length > 0 ? rows.map((_elements, _i) => {
                let _rowMaxHeight = 0

                for(let i = 0; i < _elements.length; i++){
                    const _element = _elements[i]
                    const _isFeatured = !!_element.featured && canBeFeatured
                    const _height = this.heightForImage(_element.image, _isFeatured)

                    if(isFinite(_height) && _height > _rowMaxHeight){
                        _rowMaxHeight = _height
                    }
                }

                if(limitRatio){
                    _rowMaxHeight = Math.min(gridItemWidth, _rowMaxHeight)
                }

                const _rowCount = sumBy(_elements, (o) => {
                    return o.featured && canBeFeatured ? 2 : 1
                })
                const _rowRemaining = colsPerRow > _rowCount ? colsPerRow - _rowCount : 0

                return <div
                    className={ classnames('cols', 'cols--hasVerticalPadding') }
                    key={ _i }>
                    { _rowRemaining > 0 ? <div className={ classnames('col col--empty', `col--${ _rowRemaining }-${ colsPerRow * 2 }`) } /> : null }
                    {_elements.map((_element, _j) => {
                        const _index = _element.index
                        const _indexInRow = (_rowRemaining / 2) + _j
                        const _hasImage = !!_element.image
                        const _isFeatured = !!_element.featured && canBeFeatured
                        const _isInteractive = onClickElement || _element.link

                        const _title = _element.title ? renderHTML(_element.title) : null

                        const _elContent = <div
                            className={ classnames('grid-item-wrap', {
                                'grid-item-wrap--interactive': _isInteractive
                            }) }
                            onMouseEnter={ _isInteractive ? (e) => {
                                this.setState({
                                    hoveredIndex: _index
                                })
                            } : null }
                            onMouseLeave={ _isInteractive ? (e) => {
                                this.setState({
                                    hoveredIndex: null
                                })
                            } : null }
                            onClick={ _isInteractive && !_element.link ? (e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                
                                this.onClickElement(_index, _indexInRow)
                            } : null }>
                            { _hasImage ? <div className="grid-item-image">
                                <GridSpacer
                                    height={ _rowMaxHeight } />
                                <div className="grid-item-image-layer">
                                    <div className="grid-item-image-image">
                                        <ARYImage
                                            contain={ true }
                                            image={ _element.image } />
                                        <GridSpacer
                                            height={ Math.min(_rowMaxHeight, this.heightForImage(_element.image, _isFeatured)) } />
                                    </div>
                                </div>
                            </div> : null }
                            <div className={ classnames('grid-item-text textBlock textBlock--center', textBlockClassName) }>
                                { _element.prefix ? <h6>{ renderHTML(_element.prefix) }</h6> : null }
                                { _title ? <h3
                                    className={ classnames({
                                        'p--h4': !titleAsBody,
                                        'p--p': titleAsBody
                                    }) }>{ titleAsBody ? _title : formatTitle(_title) }</h3> : null }
                                { _element.body ? this.wrapBody(_element.body) : null }
                                { elementCTA && _element.link ? <p><strong>{ elementCTA }</strong></p> : null }
                            </div>
                        </div>

                        return <div
                            key={ _index }
                            className={ classnames('grid-item col', {
                                [`col--1-${ colsPerRow }`]: !_isFeatured,
                                [`col--2-${ colsPerRow }`]: _isFeatured,
                                [`txt--${ colour }`]: !!colour,
                                'grid-item--highlighted': hasHighlight && _index === activeIndex
                            }) }>
                            { _element.link ? <Link to={ _element.link }>{ _elContent }</Link> : _elContent }
                        </div>
                    }) }
                </div>
            }) : null }
        </div>
    }
}

export default Grid