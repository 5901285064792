import React 			    from 'react'
import ReactDOM             from 'react-dom'
import classnames           from 'classnames'
import { connect }          from 'react-redux'
import {
    isFinite
} from 'lodash'

import Grid                 from './grid'

import SectionWrapper       from '../section/wrapper'
import {
    FixedTextEl,
    ScrollTextEl
} from '../section/text-el'

import {
    formatWpImageForARYImage
} from '../../lib/_helpers'

class GridSection extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const {
            title,
            elTitleAsBody,
            colBase,
            firstRowMax,
            onClickElement,
            activeIndex,
            colour,
            elements,
            textBlockClassName,
            bodyIsHTML,
            elementCTA,
            fixed,
            limitRatio,
            isActive,
            isLast
        } = this.props

        const TextElComponent = fixed ? FixedTextEl : ScrollTextEl

        return <SectionWrapper
            className="gridSection"
            naturalHeight={ true }
            fixed={ fixed }
            isLast={ isLast }
            scrollPx={ this.props.scrollPx }
            indexIsActive={ this.props.indexIsActive }>
            <div className="section-content">
                <div className="cont">
                    <div className="sectionSpacer sectionSpacer--double" />
                    <TextElComponent
                        isActive={ isActive }
                        className={ classnames({
                            [`txt--${ colour }`]: colour
                        })}
                        titleEl="h3"
                        title={ title } />
                    <div className="sectionSpacer" />
                    <Grid
                        titleAsBody={ elTitleAsBody }
                        colBase={ colBase }
                        firstRowMax={ firstRowMax }
                        onClickElement={ onClickElement }
                        activeIndex={ activeIndex }
                        colour={ colour }
                        textBlockClassName={ textBlockClassName }
                        bodyIsHTML={ bodyIsHTML }
                        elementCTA={ elementCTA }
                        limitRatio={ limitRatio }
                        elements={ elements } />
                    <div className="sectionSpacer sectionSpacer--double" />
                </div>
            </div>
        </SectionWrapper>
    }
}

export default GridSection
